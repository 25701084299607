<template>
  <button
    type="button"
    class="bg-cl-transparent brdr-none inline-flex after-burger"
    @click="openSidebarMenu"
    :aria-label="$t('Open menu')"
    data-testid="menuButton"
  >
    <template v-if="!isOpen">
      <i class="icon-burger cl-light-gray h4" />
    </template>
    <template v-else>
      <img :src="'/assets/svg/Krzyzyk.svg'" width="20px" height="20px" class="filter-gray" alt="krzyzyk">
    </template>
  </button>
</template>

<script>
import { mapState } from 'vuex'
import HamburgerIcon from '@vue-storefront/core/compatibility/components/blocks/Header/HamburgerIcon'

export default {
  mixins: [HamburgerIcon],
  computed: mapState({
    isOpen: state => state.ui.sidebar
  })
}
</script>

<style lang="scss" scoped>
.filter-gray {
  filter: invert(0.5);
}
</style>
